<template>
  <el-scrollbar :native="false" style="margin-top: 34px">
    <div style="max-height: 100vh;" @click="handleClear">
      <el-row type="flex">
        <el-col :span="12" :offset="1">
          <PageHeader :title="__('DNIS Pool Data')"></PageHeader>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="22" :offset="1">
          <el-form class="form-container">
            <PaginationToolbar
              :sort-by-options="sortByOptions"
              :content-api="contentAPI"
              :auto-refresh-content="true"
              :refresh-interval="10000"
              :key="`view-data-${dnispool.pool_id}`"
            >
            </PaginationToolbar>
          </el-form>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="22" :offset="1">
          <div class="contentListContainer" v-loading="loading">
            <el-table
              ref="campaignsDataTable"
              class="list-table"
              :data="pool_data"
              highlight-current-row
              row-key="pool_number_id"
              @row-click="handleSingleClick"
              @row-dblclick="handleDoubleClick"
            >
              <!-- Hiding Pool Numbers ID -->
              <!-- <el-table-column label="Id" prop="pool_number_id" /> -->
              <el-table-column
                :label="__('Phone Number')"
                prop="phone_number"
              />
              <el-table-column :label="__('Allocated')" min-width="150">
                <template slot-scope="scope">
                  <div style="display: flex; align-items: center">
                    <div :style="getInUseStyles(scope.row.in_use)"></div>
                    {{ showRowInUse(scope.row.in_use) }}
                  </div>
                </template>
              </el-table-column>
              <!-- Hiding the delete number func -->
              <!-- <el-table-column label="Operation">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="danger"
                    @click="handleDelete(scope.$index, scope.row)"
                    :disabled="!canWrite()"
                    >Delete</el-button
                  >
                </template>
              </el-table-column> -->
            </el-table>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-scrollbar>
</template>

<script>
import PaginationToolbar from "@/components/PaginationToolbar";
import PageHeader from "@/components/PageHeader";
import { mapActions, mapState } from "vuex";
import BaseContent from "@/views/build/content/mixins/BaseContent";

export default {
  mixins: [BaseContent],
  name: "viewData",
  components: { PageHeader, PaginationToolbar },
  props: {
    dnispool: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      viewData: false,
      sortByColumns: ["phone_number", "pool_number_id"]
    };
  },
  methods: {
    ...mapActions("dnispool", {
      contentAPI: "getPoolNumbersData",
      deletePoolNumber: "deleteDnisPoolNumber"
    }),
    capitalize(label) {
      return label.charAt(0).toUpperCase() + label.slice(1);
    },
    // handleDelete(index, row) {
    //   this.deletePoolNumber(row)
    //     .then(data => {
    //       console.log(data);
    //       this.$message({
    //         type: "success",
    //         message: data.message
    //       });
    //     })
    //     .catch(err => {
    //       this.$message({
    //         type: "success",
    //         message: err.message
    //       });
    //     });
    // },
    getInUseStyles(inUse) {
      let inUseStyles = {
        width: "16px",
        height: "16px",
        "border-radius": "8px",
        "margin-right": "10px",
        border: "3px solid"
      };

      const inUseColorMap = {
        0: "#F84856",
        1: "#51B848"
      };

      inUseStyles["border-color"] = inUseColorMap[inUse] || "#FAB800";
      return inUseStyles;
    },

    handleSingleClick(row, column, event) {
      event.stopPropagation();
    },

    handleDoubleClick(row, column, event) {
      event.stopPropagation();
    },
    handleClear() {
      this.$refs.campaignsDataTable &&
        this.$refs.campaignsDataTable.setCurrentRow();
    }
  },
  computed: {
    showRowInUse() {
      return row_in_use => {
        return row_in_use ? __("YES") : __("NO");
      };
    },
    ...mapState("dnispool", {
      loading: state => state.loading,
      pool_data: state => state.pool_data
    })
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";
</style>
